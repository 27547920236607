import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import file1tech from '../../assets/files/tech-presentation.pptx';
import file2tech from '../../assets/files/tech-procedure.pdf';
import file3tech from '../../assets/files/ambassador-presentation.pdf';
import file1business from '../../assets/files/business-game-presentation.pptx';
import file2business from '../../assets/files/business-game-procedure.pdf';
import file3business from '../../assets/files/ambassador-presentation.pdf';
import {getSchoolStats} from 'helpers/stats-helper';
import {loginUiTexts, schoolUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Logo from '../ui/logo/logo';
import Logos from 'components/ui/logos/logos';
import PopupVideo from './popup-video/popup-video';
import PopupCreateGame from './popup-create-game/popup-create-game';
import PopupDeleteGame from './popup-delete-game/popup-delete-game';
import './landing-page-school.scss';

const LandingPageSchool = (
	{
		scenarioId, 
		loadingGameId,
		games, 
		schoolData, 
		goToGame,
		createGame,
		deleteGame, 
		handleLogout, 
		updateBackground 
	}
) => {
	/* Video popup */
	const [showVideoPopup, setShowVideoPopup] = useState(false);

	/* Create game popup */
	const [showPopupCreateGame, setShowPopupCreateGame] = useState(false);

	/* Delete game popup */
	const [deleteGameId, setDeleteGameId] = useState(null);

	/* Stats */
	const {playthroughCount, studentCount} = getSchoolStats(schoolData, games);

	useEffect(() => {
		// Set background image id
		updateBackground('login', scenarioId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let file1 = null;
	let file2 = null;
	let file3 = null;
	let file1name = '';
	let file2name = '';
	let file3name = '';
	let file1button = '';
	let file2button = '';
	let file3button = '';
	if (scenarioId === 'game1') {
		file1 = file1tech;
		file2 = file2tech;
		file3 = file3tech;
		file1name = schoolUiTexts.techFilePresentationFile;
		file2name = schoolUiTexts.techProcedureFile;
		file3name = schoolUiTexts.techAmbassadorFile;
		file1button = schoolUiTexts.techFilePresentation;
		file2button = schoolUiTexts.techProcedure;
		file3button = schoolUiTexts.techAmbassador;
	} else if (scenarioId === 'game2') {
		file1 = file1business;
		file2 = file2business;
		file3 = file3business;
		file1name = schoolUiTexts.businessFilePresentationFile;
		file2name = schoolUiTexts.businessProcedureFile;
		file3name = schoolUiTexts.businessAmbassadorFile;
		file1button = schoolUiTexts.businessFilePresentation;
		file2button = schoolUiTexts.businessProcedure;
		file3button = schoolUiTexts.businessAmbassador;
	}

	return (
		<div className={'LandingPageSchool'}>
			<Logos />
			<div className='LandingPageSchool-logoWrapper'>
				<Logo scenarioId={scenarioId} page="landing" />
			</div>
			<div className="LandingPageSchool-content">
				<div className='LandingPageSchool-schoolName'>
					<span>{schoolData.name}</span>
				</div>
				<div className='LandingPageSchool-schoolStats'>
					<div className='LandingPageSchool-studentCount'>
						<span>{schoolUiTexts.landingPage.studentsField} {studentCount}</span>
					</div>
					<span>
						{'|'}
					</span>
					<div className='LandingPageSchool-playthroughCount'>
						<span>{schoolUiTexts.landingPage.playThroughField} {playthroughCount}</span>
					</div>
				</div>

				<div className='LandingPageSchool-createGameButton'>
					<Button
						classes={['login']}
						text={schoolUiTexts.landingPage.createGame}
						onClick={() => {setShowPopupCreateGame(true);}}
					/>
				</div>

				{/* Games */}
				<div className='LandingPageSchool-games'>
					<div className='LandingPageSchool-row header'>
						<div className='LandingPageSchool-cell'>
							<span>{schoolUiTexts.landingPage.game}</span>
						</div>
						<div className='LandingPageSchool-cell'>
							<span>{schoolUiTexts.landingPage.gameCode}</span>
						</div>
						<div className='LandingPageSchool-cell center'>
							<span>{schoolUiTexts.landingPage.choose}</span>
						</div>
						<div className='LandingPageSchool-cell center delete'>
							<span>{schoolUiTexts.landingPage.delete}</span><span></span>
						</div>
					</div>

					<div className="LandingPageSchool-gamesWrap">
						{games && games.map((game, index) => {
					
							return (
								<div key={index} className='LandingPageSchool-row body'>
									<div className='LandingPageSchool-cell'>
										<span>{game.name}</span>
									</div>
									<div className='LandingPageSchool-cell'>
										<span>{game.id}</span>
									</div>
									<div className='LandingPageSchool-cell'>
										<Button
											isLoading={loadingGameId === game.id}
											classes={['flatBlueLight']}
											text={schoolUiTexts.landingPage.goToGame}
											onClick={() => {goToGame(game.id);}}
										/>
									</div>
									<div 
										className="LandingPageSchool-cell delete" 
										onClick={() => {setDeleteGameId(game.id);}}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			<div className='LandingPageSchool-buttonsWrapper'>
				{file1 &&
					<div className="LandingPageSchool-manualBtn">
						<Button 
							type={'a'}
							href={file1}
							text={file1button}
							fileName={file1name}
							classes={['fullWidth', 'login', 'download']}
						/>
					</div>
				}

				{file2 &&
					<div className="LandingPageSchool-manualBtn">
						<Button 
							type={'a'}
							href={file2}
							text={file2button}
							fileName={file2name}
							classes={['fullWidth', 'login', 'download']}
						/>
					</div>
				}

				{file3 &&
					<div className="LandingPageSchool-manualBtn">
						<Button 
							type={'a'}
							href={file3}
							text={file3button}
							fileName={file3name}
							classes={['fullWidth', 'login', 'download']}
						/>
					</div>
				}

				{/* Logout button */}
				<div className="LandingPageSchool-logoutBtn">
					<Button 
						classes={['fullWidth', 'login', 'logout']} 
						text={loginUiTexts.logout}
						onClick={() => {handleLogout();}}
					/>
				</div>
			</div>

			{/* Back to select game btn */}
			<div className="LandingPageSchool-backBtn">
				<Button 
					classes={['backBtnGame', scenarioId]} 
					text={schoolUiTexts.back}
					type="a"
					href="/"
				/>
			</div>

			{/* Video popup */}
			{showVideoPopup && <PopupVideo closePopup={() => {setShowVideoPopup(false);}}/>}

			{/* Create Game Popup */}
			{showPopupCreateGame &&
				<PopupCreateGame
					createGame={createGame}
					closePopup={() => {setShowPopupCreateGame(false);}}
				/>
			}

			{deleteGameId && 
				<PopupDeleteGame 
					gameId={deleteGameId}
					games={games}
					deleteGame={deleteGame}
					closePopup={() => {setDeleteGameId(null);}}
				/>}
		</div>
	);
};

LandingPageSchool.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	loadingGameId: PropTypes.string,
	games: PropTypes.array.isRequired,
	schoolData: PropTypes.object.isRequired,
	goToGame: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default LandingPageSchool;
